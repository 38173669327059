<template>
  <sheet dense depressed no-wrap-default-slot>
    <template #head>
      <v-toolbar :color="toolbarColor" :rounded="toolbarRounded" flat>
        <v-btn
          v-if="!persistent"
          :color="showForm ? 'primary' : 'blue-grey'"
          rounded
          text
          @click="showForm = !showForm"
        >
          <icon-options-outline outlined size="1.5rem" />
          <span class="ml-4" v-text="btnLabel" />
        </v-btn>
        <v-toolbar-title v-else v-text="btnLabel" />

        <v-btn
          v-if="showReloadBtn"
          class="ml-4"
          color="primary"
          rounded
          text
          @click="onReload"
        >
          <icon-refresh-cw size="1.2rem" />
          <span class="ml-4" v-text="$t('reload.page')" />
        </v-btn>

        <v-spacer />

        <v-btn
          v-if="!persistent"
          color="blue-grey"
          icon
          @click="showForm = !showForm"
        >
          <v-icon v-text="`mdi-chevron-${showForm ? 'up' : 'down'}`" />
          <!--          <icon-chevron-up v-if="showForm" outlined size="1.5rem" />-->
          <!--          <icon-chevron-down v-else outlined size="1.5rem" />-->
        </v-btn>
      </v-toolbar>
    </template>

    <template #default>
      <v-expand-transition>
        <v-form
          v-if="showForm"
          ref="form"
          :disabled="isLocalLoading"
          class="content"
          @submit.prevent
        >
          <gw-form-observer
            ref="form-observer"
            :loading="isLocalLoading"
            color="transparent"
            hide-cancel-action
            hide-save-action
            hide-top-actions
            @cancel="reset"
            @save="filter"
          >
            <slot :apply="filter" />

            <template #bottom-actions-left>
              <slot name="bottom" />
            </template>

            <template #bottom-actions>
              <v-badge :value="isDirty" color="green" left overlap>
                <v-btn
                  :color="btnColor"
                  :loading="isLocalLoading"
                  @click="filter"
                >
                  {{ $t(searchLabel) }}
                </v-btn>
              </v-badge>
              <v-btn
                v-if="!hideResetButton"
                :disabled="isLocalLoading"
                class="ml-4"
                @click="reset"
              >
                {{ $t(resetLabel) }}
              </v-btn>
            </template>
          </gw-form-observer>
        </v-form>
      </v-expand-transition>
    </template>
  </sheet>
</template>

<script lang="ts">
import {
  Component,
  Mixins,
  Prop,
  Ref,
  VModel,
  Watch,
} from "vue-property-decorator";
import FormObserver from "@bit/planetadeleste.gui.form.form-observer";
import GlobalMixin from "@/mixins/GlobalMixin";
import FiltersMixin from "@/mixins/FiltersMixin";
import type { VForm } from "vuetify/lib";
import IconOptionsOutline from "@/components/icons/IconOptionsOutline.vue";
import { EventBus } from "@/services/event-bus";
import IconRefreshCw from "@/components/icons/IconRefreshCw.vue";
import { delay, isFunction } from "lodash";

type OnResetCallback = <T extends Record<string, any>>(obData?: T) => T;

@Component({
  components: {
    IconRefreshCw,
    IconOptionsOutline,
    FormObserver,
  },
})
export default class SheetFilters extends Mixins(GlobalMixin, FiltersMixin) {
  @VModel({ type: Object, default: () => ({}) }) obFilters!: Record<
    string,
    any
  >;
  @Ref() readonly form!: InstanceType<typeof VForm>;
  @Prop(Boolean) readonly persistent!: boolean;
  @Prop(Boolean) readonly hideResetButton!: boolean;
  @Prop(Boolean) readonly clearOnCreate!: boolean;
  @Prop(Boolean) readonly preventReset!: boolean;
  @Prop(Boolean) readonly localLoading!: boolean;
  @Prop(Boolean) readonly noVuex!: boolean;

  /**
   * Force to show reload button
   * @type {Boolean}
   * @default false
   */
  @Prop(Boolean) readonly showReloadButton!: boolean;

  @Prop(String) readonly modelName!: string;
  @Prop(Function) readonly onReset!: OnResetCallback;

  @Prop({ type: String, default: "search" }) readonly searchLabel!: string;
  @Prop({ type: String, default: "reset" }) readonly resetLabel!: string;

  showForm = false;
  isDirty = false;

  obOldFilters: Record<string, any> = {};

  get isLocalLoading() {
    return this.isLoading || this.localLoading;
  }

  get modelClassName() {
    return this.modelName || "global";
  }

  get btnLabel(): string {
    const sLabel = this.showForm ? "close" : "open";
    const sBtnLabel = this.persistent ? "filters" : `${sLabel}.filters`;

    return this.$t(sBtnLabel) as string;
  }

  get btnColor() {
    return this.isDirty ? "primary" : "blue-grey lighten-3";
  }

  get showReloadBtn() {
    if (this.showReloadButton) {
      return true;
    }

    const sRouteName = this.$route.name ?? "";
    return (
      !this.$route.params.id &&
      (sRouteName.includes("index") || sRouteName.includes("list"))
    );
  }

  get toolbarColor() {
    const iLighten = this.showForm ? "4" : "5";
    return `blue-grey lighten-${iLighten}`;
  }

  get toolbarRounded() {
    const sPrefix = this.showForm ? "t-" : "";
    return `${sPrefix}lg`;
  }

  // get isDirty(): boolean {
  //   return !isEqual(this.obOldFilters, this.obFilters);
  // }

  @Watch("obFilters", { deep: true })
  onWatchDirty() {
    this.isDirty = true;
  }

  mounted() {
    this.$nextTick(() => {
      if (this.persistent) {
        this.showForm = true;
      }
    });

    this.sync();
  }

  created() {
    if (this.clearOnCreate && !this.noVuex) {
      this.onSetFilters({});
    }
  }

  filter() {
    if (!this.noVuex) {
      this.onSetFilters(this.obFilters);
    }

    this.sync();

    this.$emit("apply", true);
  }

  reset() {
    if (!this.preventReset) {
      const obData = isFunction(this.onReset) ? this.onReset({}) : {};

      if (!this.noVuex) {
        this.onSetFilters(obData);
      }

      this.$emit("input", obData);
    }

    this.sync();
    this.$emit("reset", true);
  }

  /**
   * Copy filters to old filters var
   */
  sync(): void {
    delay(() => {
      this.isDirty = false;
      // this.obOldFilters = { ...this.obFilters };
    }, 500);
  }

  onReload() {
    EventBus.emit("reload.index");
  }
}
</script>
